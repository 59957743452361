import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { RadioGroup, Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { MacScrollbar } from 'mac-scrollbar';
import { ViewportList } from 'react-viewport-list';
import { UserRejectedRequestError } from 'viem';
import { useWalletClient } from 'wagmi';
import { type ChainData } from '@/shared/assets/chains';
import { isWatchable, type Token } from '@/shared/assets/tokens';
import { NATIVE_TOKEN_ADDRESS } from '@/shared/assets/tokens/constants';
import { ChainLogo, TokenLogo, Tooltip } from '@/shared/components';
import LoadingSpinner from '@/shared/components/LoadingSpinner';
import { isTestnet } from '@/shared/featureFlags';
import { useScreenSize } from '@/shared/hooks';
import useTracking from '@/shared/hooks/useTracking';
import { SearchIcon, CloseIcon, ArrowIcon, EmojiSadIcon } from '@/shared/icons/large';
import { PlusIcon } from '@/shared/icons/small';
import { chainflipChainMap, type TokenAmount } from '@/shared/utils';
import { useChainBalances } from '../../hooks/useChainBalances';
import useChains from '../../hooks/useChains';
import useSwapRequestStore from '../../hooks/useSwapRequestStore';
import { useTokens } from '../../hooks/useTokens';
import { SwapEvents, type SwapTrackEvents } from '../../types/track';
import Input from '../Input';

const ChainList = ({
  chain,
  onChange,
}: {
  chain: ChainData | undefined;
  onChange: (chain: ChainData) => void;
}) => {
  const { chains } = useChains();
  const { isMobile } = useScreenSize();

  return (
    <RadioGroup
      data-testid="chain-radio-group"
      by="id"
      onChange={onChange}
      className="mt-4 flex flex-row gap-1 overflow-auto md:flex-col"
      value={chain}
    >
      {chains.map((c) => (
        <RadioGroup.Option value={c} key={c.id}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="flex cursor-pointer items-center rounded-md border border-transparent p-2 text-cf-light-3 transition duration-100 ease-out hover:border-cf-gray-4 hover:bg-cf-gray-3-5 hover:text-cf-white ui-checked:cursor-default ui-checked:border-cf-gray-5 ui-checked:bg-cf-gray-4 ui-checked:text-cf-white ui-checked:hover:border-cf-gray-5 hover:ui-checked:bg-cf-gray-4"
          >
            <div>
              <ChainLogo chain={c} size={isMobile ? 'medium' : 'large'} />
            </div>
            <div className="flex gap-x-2">
              <div className="ml-2 truncate font-aeonikMedium text-14 ">{c.name}</div>
            </div>
          </motion.div>
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};

const SearchInput = ({ value, onChange }: { value: string; onChange: (value: string) => void }) => (
  <div className="group relative flex flex-1">
    <Input
      className={classNames(
        'peer w-full rounded-md px-4 py-2 text-14 text-cf-white transition duration-100 ease-out placeholder:pl-7',
        // default
        'border border-cf-gray-4 bg-cf-gray-3-5 shadow-grayPop3 placeholder:text-cf-light-2',
        // focus
        'focus:border-cf-gray-5 focus:bg-cf-gray-4/70 focus:placeholder:text-transparent ',
        // hover
        'hover:border-cf-gray-5 hover:bg-cf-gray-4 hover:placeholder:text-cf-light-3',
      )}
      onChange={(e) => onChange(e.target.value)}
      placeholder="Search by symbol, name or address"
      value={value}
    />
    <div className="pointer-events-none absolute flex h-full w-12 items-center justify-center text-transparent group-focus-within:!text-transparent peer-placeholder-shown:text-cf-light-1">
      <SearchIcon />
    </div>
  </div>
);

const DisplayToken = ({
  token,
  index,
  viewPortDirty,
  balance,
}: {
  token: Token;
  index: number;
  viewPortDirty: boolean;
  balance: TokenAmount | undefined;
}) => {
  const { data: client } = useWalletClient();
  const canAddToken = client != null && isWatchable(token);

  const watchToken = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!canAddToken) return;

    e.stopPropagation();

    try {
      await client.watchAsset({
        type: 'ERC20',
        options: {
          address: token.address,
          symbol: token.canonicalSymbol ?? token.symbol, // metamask rejects asset if symbol does not match contract symbol
          decimals: token.decimals,
          image: token.logo,
        },
      });
    } catch (err) {
      if (!(err instanceof UserRejectedRequestError)) {
        throw err;
      }
    }
  };

  return (
    <RadioGroup.Option
      value={token}
      className="group rounded-md border-cf-gray-5 p-3 text-14 transition duration-100 ease-out hover:border hover:border-cf-gray-5 hover:bg-cf-gray-4 hover:p-[11px] ui-checked:border ui-checked:border-cf-gray-5 ui-checked:bg-cf-gray-4 ui-checked:p-[11px] ui-checked:opacity-60 ui-not-checked:cursor-pointer"
    >
      {({ checked }) => (
        <motion.div
          initial={viewPortDirty ? { opacity: 1, translateX: 0 } : { opacity: 0, translateX: 15 }}
          animate={{
            opacity: 1,
            translateX: 0,
          }}
          transition={{ duration: 0.3, delay: index * 0.05 }}
          className="flex items-center gap-x-3"
        >
          <div>
            <TokenLogo token={token} size="large" />
          </div>
          <div className="flex flex-col">
            <div className="flex items-center gap-x-1">
              <div
                className={classNames(
                  'mb-[-2px] whitespace-nowrap font-aeonikBold text-cf-light-3 transition',
                  !checked && 'group-hover:text-cf-white',
                )}
              >
                {token.symbol}
              </div>
              {canAddToken && (
                <Tooltip content="Add token to wallet" tabable={false}>
                  <button
                    // tabbing is kind of messed up in the radio options :(
                    tabIndex={-1}
                    type="button"
                    className={classNames(
                      'flex h-4 w-4 items-center justify-center rounded-full border border-cf-gray-4',
                      'bg-cf-gray-3-5 text-cf-light-3 opacity-0 transition group-hover:opacity-100',
                      'hover:bg-cf-gray-4 hover:text-cf-white',
                    )}
                    onClick={watchToken}
                  >
                    <PlusIcon className="h-[14px] w-[14px]" />
                  </button>
                </Tooltip>
              )}
            </div>
            <div className="flex flex-row items-center space-x-1.5">
              <div className="whitespace-nowrap text-cf-light-2">{token.name}</div>
              {balance?.gt(0) && (
                <div className="whitespace-nowrap font-aeonikRegular text-12 text-cf-white">
                  / {balance.toCapped()} {token.symbol}
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames(
              'ml-auto translate-x-[-5px] text-cf-white opacity-0 transition',
              'group-hover:translate-x-0 group-hover:opacity-100',
              checked && 'hidden',
            )}
          >
            <ArrowIcon />
          </div>
          <div className="ml-auto hidden font-aeonikMedium ui-checked:block">Selected</div>
        </motion.div>
      )}
    </RadioGroup.Option>
  );
};

export default function TokenModal({
  isDestination,
  isOpen,
  onClose,
}: {
  isDestination: boolean;
  isOpen: boolean;
  onClose: () => void;
}): JSX.Element {
  const track = useTracking<SwapTrackEvents>();
  const { isMobile } = useScreenSize();
  const sourceToken = useSwapRequestStore((state) => state.srcToken);
  const destToken = useSwapRequestStore((state) => state.destToken);
  const [chain, setChain] = useState<ChainData | undefined>(chainflipChainMap.Ethereum);
  const { tokens, isLoading: tokensLoading } = useTokens(isOpen ? chain?.id : undefined);
  const { balances } = useChainBalances(isOpen ? chain : undefined);
  const [viewPortDirty, setViewPortDirty] = useState(false);

  const selectedToken = isDestination ? destToken : sourceToken;
  const setSrcToken = useSwapRequestStore((state) => state.setSrcToken);
  const setDestToken = useSwapRequestStore((state) => state.setDestToken);

  const setSelectedToken = isDestination ? setDestToken : setSrcToken;
  const viewportRef = useRef<HTMLDivElement | null>(null);

  const [filter, setFilter] = useState('');

  const otherToken = isDestination ? sourceToken : destToken;
  const setOtherToken = isDestination ? setSrcToken : setDestToken;

  const regex =
    filter === ''
      ? // match anything
        /./
      : // match the filtered text after escaping regex characters
        new RegExp(filter.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');

  const filteredTokens = tokens
    .filter((t) => regex.test(t.symbol) || regex.test(t.name) || regex.test(t.address))
    .sort((a, b) =>
      // display tokens with balance before tokens without balance
      balances[a.address]?.gt(0) && !balances[b.address]?.gt(0) ? -1 : 1,
    );

  const handleReopen = useCallback(() => {
    if (isOpen && selectedToken) {
      setChain(selectedToken.chain);
      setSelectedToken(selectedToken);
    }
  }, [selectedToken, isOpen]);

  useEffect(() => {
    handleReopen();
  }, [handleReopen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/* backdrop */}
          <div className="fixed inset-0 bg-black/30" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          afterLeave={() => setFilter('')}
        >
          <div className="fixed inset-0 z-20 flex w-full items-center justify-center font-aeonikRegular text-white backdrop-blur-[5px]">
            <div className="flex h-full w-full items-center justify-center p-4 md:w-[672px]">
              <Dialog.Panel className=" relative w-full rounded-md border border-cf-gray-4 bg-cf-gray-3 shadow-grayPop1">
                <div className="flex h-[500px] flex-col md:h-[700px] md:flex-row">
                  <div className="absolute right-0 top-0 z-10 p-4">
                    <button
                      onClick={onClose}
                      type="button"
                      className="flex h-6 w-6 items-center justify-center rounded-full text-cf-light-2 outline-none transition hover:bg-cf-gray-5 "
                    >
                      <CloseIcon className="transition hover:text-white" />
                    </button>
                  </div>
                  {isMobile && (
                    <div className="flex flex-col gap-y-2 p-4 pb-0">
                      <div className="font-aeonikMedium text-20 text-cf-white">Select asset</div>
                      <SearchInput value={filter} onChange={setFilter} />
                    </div>
                  )}
                  <div
                    className={classNames(
                      `flex flex-col border-cf-gray-4 p-4 pb-0 md:border-r`,
                      isTestnet ? 'md:w-[240px]' : 'md:w-[200px]',
                    )}
                  >
                    <div className="font-aeonikMedium text-20 text-cf-white">Chains</div>
                    <ChainList
                      chain={chain}
                      onChange={(c) => {
                        setChain(c);
                        setViewPortDirty(false);
                      }}
                    />
                  </div>
                  <div className="relative flex-1 flex-col space-y-2 overflow-auto p-4">
                    <div className="font-aeonikMedium text-20 text-cf-white">Assets</div>
                    {!isMobile && <SearchInput value={filter} onChange={setFilter} />}
                    <MacScrollbar skin="dark" ref={viewportRef} className="h-full max-h-[580px]">
                      {filteredTokens.length !== 0 ? (
                        <RadioGroup
                          className="flex flex-col space-y-2"
                          data-testid="token-radio-group"
                          by={(t1, t2) => t1 === t2}
                          onChange={(token) => {
                            if (isDestination) {
                              track(SwapEvents.SelectAssetTo, {
                                props: {
                                  assetFrom: `${token.chain.name}.${token.symbol}`,
                                  contractAddress: token.address,
                                  isNative: token.address === NATIVE_TOKEN_ADDRESS,
                                },
                              });
                            } else {
                              track(SwapEvents.SelectAssetFrom, {
                                props: {
                                  assetFrom: `${token.chain.name}.${token.symbol}`,
                                  contractAddress: token.address,
                                  isNative: token.address === NATIVE_TOKEN_ADDRESS,
                                },
                              });
                            }
                            setSelectedToken(token);
                            if (
                              token?.chain.id === otherToken?.chain.id &&
                              token?.address === otherToken?.address
                            ) {
                              // switch tokens when selecting token that is already selected in other field
                              setOtherToken(selectedToken);
                            }
                            onClose();
                          }}
                          value={selectedToken}
                        >
                          <ViewportList
                            viewportRef={viewportRef}
                            items={filteredTokens}
                            onViewportIndexesChange={([, b]) => {
                              if (!viewPortDirty && b > 6) {
                                setViewPortDirty(true);
                              }
                            }}
                          >
                            {(token, index) => (
                              <DisplayToken
                                key={token.chain.id + token.address}
                                token={token}
                                index={index}
                                viewPortDirty={viewPortDirty}
                                balance={balances[token.address]}
                              />
                            )}
                          </ViewportList>
                        </RadioGroup>
                      ) : (
                        <div className="flex h-full flex-col items-center justify-center text-14 text-cf-light-1">
                          {tokensLoading ? (
                            <LoadingSpinner />
                          ) : (
                            <div className="flex flex-col items-center justify-center space-y-1">
                              <EmojiSadIcon className="text-cf-light-2" width={60} height={60} />
                              <span className="px-20 text-center text-14 text-cf-light-1">
                                {filter === '' ? (
                                  <>
                                    No assets available at this time. Please try a different chain.
                                  </>
                                ) : (
                                  <>No asset found. Please try a different search.</>
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </MacScrollbar>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}
