import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { toast } from 'sonner';
import { CopyButton, DisableSSR, Link, WarningModal } from '@/shared/components';
import { usePrevious, useSafary } from '@/shared/hooks';
import useBoolean from '@/shared/hooks/useBoolean';
import { type SafarTrackEvents, SafaryEvent } from '@/shared/hooks/useSafary';
import { defaultToastClassnames } from '@/shared/hooks/useToast';
import useTokenPrice from '@/shared/hooks/useTokenPrice';
import useTracking from '@/shared/hooks/useTracking';
import { SettingsIcon } from '@/shared/icons/large';
import { CloseCircleIcon } from '@/shared/icons/small';
import { TokenAmount, getChainflipNetwork } from '@/shared/utils';
import { BOOST_TOASTER_ID } from './BoostToggle';
import useBoost from '../../../hooks/useBoost';
import { useBoostPoolsDepth } from '../../../hooks/useBoostLiquidityState';
import useFormValidation from '../../../hooks/useFormValidation';
import usePriceDelta from '../../../hooks/usePriceDelta';
import useSwapRequestStore from '../../../hooks/useSwapRequestStore';
import { integrationManager } from '../../../integrations';
import { SwapEvents, type SwapTrackEvents } from '../../../types/track';
import WalletActionButton from '../../WalletActionButton';
import AddressInput from '../AddressInput';
import SettingsModal from '../SettingsModal';
import TokenFields from '../TokenFields';

type WarningType = 'existentialDeposit' | 'lowLiquidity';
const warnings = {
  existentialDeposit: {
    title: 'DOT amount is below minimum',
    content: (
      <>
        If your destination address DOT balance is below the{' '}
        <Link
          href="https://support.polkadot.network/support/solutions/articles/65000168651-what-is-the-existential-deposit"
          target="_blank"
          underline
        >
          Existential Deposit
        </Link>
        , your funds will be lost.
      </>
    ),
  },
  lowLiquidity: {
    title: 'Liquidity is low',
    content: (
      <>
        We&apos;re experiencing problems getting Liquidity Provider quotes and can&apos;t guarantee
        good pricing at this time.
      </>
    ),
  },
};

export default function SwapCard(): JSX.Element {
  const router = useRouter();
  const track = useTracking<SwapTrackEvents>();
  const safary = useSafary<SafarTrackEvents>();
  const srcToken = useSwapRequestStore((state) => state.srcToken);
  const destToken = useSwapRequestStore((state) => state.destToken);
  const destinationAddress = useSwapRequestStore((state) => state.destinationAddress);
  const refundAddress = useSwapRequestStore((state) => state.refundAddress);
  const selectedRoute = useSwapRequestStore((state) => state.selectedRoute);
  const setSrcAmount = useSwapRequestStore((state) => state.setSrcAmount);
  const { setValidator, validateForm } = useFormValidation({
    srcAmount: () => false,
    destinationAddress: () => false,
    destAmount: () => false,
    refundAddress: () => true,
    minimumPrice: () => true,
  });
  const { price: srcTokenPrice } = useTokenPrice(selectedRoute?.srcToken);
  const { price: destTokenPrice } = useTokenPrice(selectedRoute?.destToken);

  const {
    value: showSettingsModal,
    setTrue: openSettingsModal,
    setFalse: closeSettingsModal,
  } = useBoolean(false);
  const {
    value: showWarningModal,
    setTrue: openWarningModal,
    setFalse: closeWarningModal,
  } = useBoolean(false);
  const [warning, setWarning] = useState<WarningType>();
  const warningAccepted = useRef<Partial<Record<WarningType, boolean>>>({});

  const { boostActive } = useBoost();
  const { maxBoostSwapAmounts } = useBoostPoolsDepth({
    token: srcToken,
  });

  const previousSelectedRoute = usePrevious(selectedRoute);
  const { delta, isLowLiquidity } = usePriceDelta(selectedRoute);

  const { mutate: prepareSwap, isPending: isPreparingSwap } = useMutation({
    mutationFn: async () => {
      if (!validateForm()) return;

      const showExistentialDepositWarning =
        (destToken?.chain.id === 'dot' || destToken?.chain.id === 'dot-testnet') &&
        selectedRoute?.destAmount?.lt(1.1e10);

      if (showExistentialDepositWarning && !warningAccepted.current.existentialDeposit) {
        setWarning('existentialDeposit');
        openWarningModal();
        return;
      }

      if (
        isLowLiquidity &&
        getChainflipNetwork() !== 'perseverance' &&
        !warningAccepted.current.lowLiquidity
      ) {
        if (selectedRoute) {
          track(SwapEvents.ShowLiquidityAlert, {
            props: {
              assetFrom: `${selectedRoute.srcToken.chain.name}.${selectedRoute.srcToken.symbol}`,
              assetFromAmount: selectedRoute.srcAmount.toFixed(),
              assetTo: `${selectedRoute.destToken.chain.name}.${selectedRoute.destToken.symbol}`,
              quotedAmount: selectedRoute.destAmount.toFixed(),
              priceDelta: delta.toFixed(),
              integration: selectedRoute.integration,
            },
          });
        }

        setWarning('lowLiquidity');
        openWarningModal();
        return;
      }

      if (!selectedRoute) {
        toast.error('No routes available, please try selecting a different pair of assets.');
        return;
      }

      safary(SafaryEvent.SwapConnectEvent, 'trackSwapConnect', {
        fromAmount: Number(selectedRoute.srcAmount.toCapped()),
        fromCurrency: selectedRoute.srcToken.symbol,
        fromAmountUSD: Math.round(
          (selectedRoute.srcAmount.mul(srcTokenPrice ?? 0).toNumber() * 100) / 100,
        ),
        fromWalletAddress: refundAddress,
        fromChain: selectedRoute.srcToken.chain.name,
        toAmount: Number(selectedRoute.destAmount.toCapped()),
        toCurrency: selectedRoute.destToken.symbol,
        toAmountUSD: Math.round(
          (selectedRoute.destAmount.mul(destTokenPrice ?? 0).toNumber() * 100) / 100,
        ),
        toWalletAddress: destinationAddress,
        toChain: selectedRoute.destToken.chain.name,
      });

      track(SwapEvents.CtaReviewSwap, {
        props: {
          assetFrom: `${selectedRoute.srcToken.chain.name}.${selectedRoute.srcToken.symbol}`,
          assetFromAmount: selectedRoute.srcAmount.toFixed(),
          assetTo: `${selectedRoute.destToken.chain.name}.${selectedRoute.destToken.symbol}`,
          quotedAmount: selectedRoute.destAmount.toFixed(),
          estRate: selectedRoute.destAmount.ratio(selectedRoute.srcAmount).toFixed(),
          priceDelta: delta?.toFixed() ?? '-',
          isNative: selectedRoute.integration === 'chainflip',
          integration: selectedRoute.integration,
          isBoostable: boostActive,
        },
      });

      const preparedSwap = await integrationManager.prepareSwap(
        selectedRoute,
        destinationAddress,
        refundAddress,
      );
      await router.push(`/${preparedSwap.integration}/${preparedSwap.id}`);
    },
  });

  useEffect(() => {
    if (
      boostActive &&
      selectedRoute?.integration === 'chainflip' &&
      // Prevent rendering toast if input haven't changed
      !previousSelectedRoute?.srcAmount.eq(selectedRoute.srcAmount)
    ) {
      if (selectedRoute.integrationData.isBoosted) {
        toast.dismiss(BOOST_TOASTER_ID);
      } else {
        const srcAsset = selectedRoute.srcToken.chainflipId;
        const maxAmount = maxBoostSwapAmounts?.[srcAsset];
        const maxTokenAmount = TokenAmount.fromAsset(maxAmount, srcAsset);

        toast('Not enough Boost liquidity', {
          id: BOOST_TOASTER_ID,
          icon: <CloseCircleIcon className="text-cf-red-1" />,
          className: `text-12 ${defaultToastClassnames}`,
          description: maxTokenAmount && (
            <div className="mb-[-5px] flex flex-row items-center">
              <div className="inline-block pt-0 align-super">
                <span className="text-cf-light-2">Please try an amount lower than </span>
                <span
                  className="cursor-pointer underline"
                  onClick={() => setSrcAmount(maxTokenAmount)}
                >
                  {maxTokenAmount.toCapped()} {selectedRoute.srcToken.symbol}
                </span>
              </div>
              <span className="inline-block pl-[4px]">
                <CopyButton size="small" textToCopy={maxTokenAmount.toCapped()} />
              </span>
            </div>
          ),
        });
      }
    }
  }, [selectedRoute]);

  return (
    <DisableSSR>
      <div className="relative flex flex-col items-center">
        <div
          className={classNames(
            'flex w-full flex-col space-y-6 rounded-md border border-cf-gray-4 bg-cf-gray-3 p-4 shadow-grayPop1 backdrop-blur-[6px] md:w-[488px]',
          )}
        >
          <div className="flex items-center justify-between">
            <div className="font-aeonikMedium text-20 text-white">Swap</div>
            <button
              data-testid="open-settings"
              type="button"
              className="ml-auto rounded-md border border-cf-gray-4 bg-cf-gray-3-5 p-1 transition duration-100 ease-out hover:border-cf-gray-5 hover:bg-cf-gray-4"
              onClick={() => {
                track(SwapEvents.ViewSettings, {
                  props: {
                    assetFrom: srcToken ? `${srcToken.chain.name}.${srcToken.symbol}` : '-',
                    assetTo: destToken ? `${destToken.chain.name}.${destToken.symbol}` : '-',
                  },
                });
                openSettingsModal();
              }}
            >
              <SettingsIcon className="text-cf-light-3" />
            </button>
          </div>
          <div className="pb-2">
            <TokenFields setValidator={setValidator} />
          </div>
          <div className="flex flex-col gap-4">
            <AddressInput type="refund" setValidator={setValidator} token={srcToken} />
            <AddressInput type="destination" setValidator={setValidator} token={destToken} />
          </div>
          <WalletActionButton
            data-testid="review-swap"
            requireConnection={Boolean(selectedRoute && selectedRoute.integration !== 'chainflip')}
            chainId={selectedRoute?.srcToken.chain.id}
            disabled={isPreparingSwap}
            onClick={() => prepareSwap()}
            fullWidth
          >
            Review swap
          </WalletActionButton>
        </div>
      </div>
      <SettingsModal isOpen={showSettingsModal} onClose={closeSettingsModal} />
      {warning && (
        <WarningModal
          isOpen={showWarningModal}
          onAccept={() => {
            if (warning === 'lowLiquidity' && selectedRoute) {
              track(SwapEvents.CtaLiquidityAlertProceed, {
                props: {
                  assetFrom: `${selectedRoute.srcToken.chain.name}.${selectedRoute.srcToken.symbol}`,
                  assetFromAmount: selectedRoute.srcAmount.toFixed(),
                  assetTo: `${selectedRoute.destToken.chain.name}.${selectedRoute.destToken.symbol}`,
                  quotedAmount: selectedRoute.destAmount.toFixed(),
                  integration: selectedRoute.integration,
                },
              });
            }

            warningAccepted.current[warning] = true;
            closeWarningModal();
            prepareSwap();
          }}
          onClose={() => {
            if (warning === 'lowLiquidity' && selectedRoute) {
              track(SwapEvents.LiquidityAlertCancel, {
                props: {
                  assetFrom: `${selectedRoute.srcToken.chain.name}.${selectedRoute.srcToken.symbol}`,
                  assetFromAmount: selectedRoute.srcAmount.toFixed(),
                  assetTo: `${selectedRoute.destToken.chain.name}.${selectedRoute.destToken.symbol}`,
                  quotedAmount: selectedRoute.destAmount.toFixed(),
                  integration: selectedRoute.integration,
                },
              });
            }

            closeWarningModal();
          }}
          title={warnings[warning].title}
          content={warnings[warning].content}
        />
      )}
    </DisableSSR>
  );
}
