import { useEffect, useMemo, useState } from 'react';
import { type BaseChainflipAsset } from '@chainflip/utils/chainflip';
import { isNullish } from '@chainflip/utils/guard';
import { formatUsdValue } from '@chainflip/utils/number';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import { type LineData } from 'lightweight-charts';
import { MacScrollbar } from 'mac-scrollbar';
import { ChainflipTransparentLogo } from '@/shared/assets/chain-transparent-logos';
import { type Token } from '@/shared/assets/tokens';
import {
  Modal,
  SkeletonLine,
  Link,
  TVChartContainer,
  TokenWithChainByAsset,
  TokenLogo,
} from '@/shared/components';
import QuestionMarkTooltip from '@/shared/components/QuestionMarkTooltip';
import { useEffectWithCleanup } from '@/shared/hooks';
import useMarketData from '@/shared/hooks/useMarketData';
import { usePriceOracle } from '@/shared/hooks/usePriceOracle';
import useTracking from '@/shared/hooks/useTracking';
import { ArrowIcon } from '@/shared/icons/large';
import { ChartIcon, WindowIcon } from '@/shared/icons/small';
import { TokenAmount, formatWithNumeral } from '@/shared/utils';
import { chainflipAssetMap, getChainflipAsset } from '@/shared/utils/env';
import {
  type RouteResponse,
  type Integration,
  integrationManager,
  type ChainflipRouteResponse,
} from '../../integrations';
import { SwapEvents, type SwapTrackEvents } from '../../types/track';
import { getAmountFromRate } from '../../utils/helpers';
import Card from '../Card';

const Platform = ({ integration }: { integration: Integration }) => (
  <div className="flex items-center ">
    {integration === 'chainflip' && (
      <div className="flex h-4 w-4 items-center justify-center rounded-full">
        <ChainflipTransparentLogo className="h-3 w-3" />
      </div>
    )}
    <span className="font-aeonikMedium text-12 text-cf-light-2">
      {integrationManager.getName(integration)}
    </span>
  </div>
);

type RouteStepBaseProps = {
  srcToken: Token;
  amount: string;
  usdAmount: string | JSX.Element;
};

type RouteStepWithDestProps = RouteStepBaseProps & {
  destToken: Token;
  protocolName: string;
  protocolLink: string;
};

type RouteStepProps = RouteStepBaseProps | RouteStepWithDestProps;

const RouteStep = ({ srcToken, amount, usdAmount, ...rest }: RouteStepProps) => {
  const isLastStep = !('destToken' in rest);

  return (
    <div className="flex flex-col justify-center space-y-3 last-of-type:pr-5">
      <div className="flex items-center justify-between space-x-6">
        <div className="flex items-center space-x-3">
          {srcToken.chainflipId ? (
            <TokenWithChainByAsset asset={srcToken.chainflipId} size="large" />
          ) : (
            <TokenLogo token={srcToken} size="large" />
          )}

          <div className="flex flex-col font-aeonikMedium">
            <span className="whitespace-nowrap text-16 font-bold text-cf-white">
              {amount} {srcToken.symbol}
            </span>
            <span className="text-12 text-cf-light-2">{usdAmount}</span>
          </div>
        </div>
        {!isLastStep && <ArrowIcon className="text-cf-light-2" />}
      </div>
    </div>
  );
};

const MarketDataCard = ({
  step,
  asset,
  setWidth,
  width,
}: {
  step: ChainflipRouteResponse['steps'][number];
  asset: BaseChainflipAsset;
  setWidth: (width: number) => void;
  width: number | undefined;
}) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const quoteAsset = chainflipAssetMap.Usdc;

  const estimatedSwapPrice =
    step.destToken === quoteAsset
      ? step.destAmount.ratio(step.srcAmount)
      : step.srcAmount.ratio(step.destAmount);

  const swapRateTokenAmount = TokenAmount.fromWholeUnits(estimatedSwapPrice, quoteAsset.decimals);
  const { marketData, latestPrice } = useMarketData({
    asset,
    numberOfDays: 3,
  });

  const lastIndexPrice = marketData?.prices.findLast((p): p is LineData => 'value' in p)?.value;

  const poolVolume24Hours = useMemo(
    () =>
      marketData?.volumes
        .slice(-24)
        .reduce((total, vol) => ('value' in vol ? total + vol.value : total), 0),
    [marketData],
  );

  const delta =
    lastIndexPrice === undefined
      ? undefined
      : estimatedSwapPrice.minus(lastIndexPrice).div(lastIndexPrice).times(100);
  const priceDelta =
    lastIndexPrice === undefined ? undefined : estimatedSwapPrice.minus(lastIndexPrice);

  const deltaColor = delta?.isPositive() ? 'text-cf-green-3' : 'text-cf-red-1';

  const preciseUsdPrice = new BigNumber(latestPrice ?? 0).decimalPlaces(4).toFormat();

  useEffectWithCleanup(
    (cleanup) => {
      if (!ref) return;

      const observer = new ResizeObserver((entries) => {
        setWidth(entries[0].borderBoxSize[0].inlineSize);
      });

      observer.observe(ref);

      cleanup(() => observer.disconnect());
    },
    [ref],
  );

  return (
    <Card
      ref={setRef}
      style={{ width: width && `${width}px` }}
      className="flex w-full min-w-fit items-stretch space-x-10 space-y-4 border border-cf-gray-4 bg-cf-gray-3-5 p-5 shadow-grayPop2 md:min-w-full"
    >
      <div className="flex flex-1 flex-col">
        <div className="flex items-center space-x-1">
          <Link
            className="flex items-center gap-x-1 font-aeonikMedium text-12 text-cf-light-3"
            target="_blank"
            href={`${process.env.NEXT_PUBLIC_EXPLORER_URL}/pools/${asset}`}
          >
            {chainflipAssetMap[asset].symbol}/{quoteAsset.symbol}
            <WindowIcon />
          </Link>
          &nbsp;·
          <div className="flex items-center space-x-1">
            <ChainflipTransparentLogo className="h-3 w-3" />
            <span className="font-aeonikMedium text-12 text-cf-light-2">Chainflip</span>
          </div>
        </div>
        <div className="font-aeonikMedium text-30 text-cf-white">
          {latestPrice ? (
            <span>
              {preciseUsdPrice} {quoteAsset.symbol}
            </span>
          ) : (
            <SkeletonLine width={150} />
          )}
        </div>
        <div className="mt-4 flex flex-col space-y-[10px]">
          {(
            [
              {
                label: 'Pool Price',
                value: `${swapRateTokenAmount.toCapped()} ${quoteAsset.symbol}`,
              },
              {
                label: 'Est. Price Delta',
                tooltip: 'The difference between the global index price and the estimated rate',
                value:
                  !isNullish(delta) && !isNullish(priceDelta) ? (
                    <>
                      ({formatWithNumeral(delta.toFixed(2))}%) /{' '}
                      <span className={deltaColor}>
                        ({delta.isPositive() ? '+' : '-'}${priceDelta.abs().toFixed(2)})
                      </span>
                    </>
                  ) : (
                    <SkeletonLine width={100} />
                  ),
              },
              {
                label: 'Index Price',
                value: !isNullish(lastIndexPrice) ? (
                  <span>{formatUsdValue(lastIndexPrice)}</span>
                ) : (
                  <SkeletonLine width={100} />
                ),
              },
              {
                label: 'Pool Volume (24H)',
                value: !isNullish(poolVolume24Hours) ? (
                  <span>{formatUsdValue(poolVolume24Hours, false)}</span>
                ) : (
                  <SkeletonLine width={100} />
                ),
              },
              {
                label: 'Global Volume (24H)',
                value: !isNullish(marketData?.globalVolume) ? (
                  <span>{formatUsdValue(marketData?.globalVolume, false)}</span>
                ) : (
                  <SkeletonLine width={100} />
                ),
              },
            ] as const
          ).map(({ label, value, ...rest }) => (
            <div
              key={label}
              className="flex items-center justify-between font-aeonikMedium text-13"
            >
              <div className="flex items-center space-x-2">
                <span className="text-cf-light-2">{label}</span>
                {'tooltip' in rest && <QuestionMarkTooltip content={rest.tooltip} />}
              </div>
              <span className="text-cf-white">{value}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="h-[270px] w-[600px]">
        <TVChartContainer
          asset={asset}
          disabledFeatures={['left_toolbar', 'header_indicators']}
          settings={{ 'paneProperties.background': '#1D1D1D' }}
        />
      </div>
    </Card>
  );
};

const Route = ({ route }: { route: RouteResponse }) => {
  const { watchTokens, getPrice, isLoading } = usePriceOracle();

  const handleUSDAmount = (amount: TokenAmount, token: Token) => {
    const rate = getPrice(token);
    if (!rate && isLoading) return <SkeletonLine />;
    if (!rate) return '$?';
    return `$${getAmountFromRate(amount, rate).toFixed(2)}`;
  };

  const steps = route.steps
    .map<RouteStepProps & { key: string }>((step) => ({
      key: `${step.srcToken.name}-${step.destToken.name}`,
      srcToken: step.srcToken,
      destToken: step.destToken,
      amount: step.srcAmount.toFormatted(),
      usdAmount: handleUSDAmount(step.srcAmount, step.srcToken),
      protocolName: step.protocolName,
      protocolLink: step.protocolLink,
    }))
    .concat([
      {
        key: `${route.destToken.name}-dest`,
        srcToken: route.destToken,
        amount: route.destAmount.toFormatted(),
        usdAmount: handleUSDAmount(route.destAmount, route.destToken),
      },
    ]);

  useEffect(
    () => watchTokens(steps.map((step) => step.srcToken)),
    steps.flatMap((step) => [step.srcToken.chain.id, step.srcToken.address]),
  );

  return (
    <div className="flex items-center space-x-6">
      {steps.map(({ key, ...step }) => (
        <RouteStep key={key} {...step} />
      ))}
    </div>
  );
};

export default function MarketData({
  disabled,
  spinner,
  route,
  label,
  className,
}: {
  disabled?: boolean;
  spinner?: JSX.Element;
  route?: RouteResponse;
  label?: string;
  className?: string;
}): JSX.Element | null {
  const track = useTracking<SwapTrackEvents>();
  const [showModal, setShowModal] = useState(false);
  const [width, setWidth] = useState<number>();

  if (!route) {
    setShowModal(false);
    return null;
  }

  const chainflipRoute = route.integration === 'chainflip';

  return (
    <>
      <Modal
        width="1024px"
        active={showModal}
        className="!rounded-md border-cf-gray-3 bg-cf-gray-3 !pt-6 shadow-grayPop1"
        onCancel={() => setShowModal(false)}
        headingJSX={
          <div className="flex items-center space-x-2">
            <span className="font-aeonikMedium text-20 text-cf-white">Market Data</span>
            {spinner}
          </div>
        }
      >
        <MacScrollbar skin="dark" className="max-h-[80vh] min-w-full">
          <div className="flex flex-col space-y-6">
            <Card
              className="flex min-w-full flex-col space-y-4 border border-cf-gray-4 bg-cf-gray-3-5 p-5 shadow-grayPop2"
              style={{ width: width && `${width}px` }}
            >
              <div className="flex items-center space-x-2">
                <span className="font-aeonikMedium text-20  text-cf-white">Route</span>
                <Platform integration={route.integration} />
              </div>
              <Route route={route} />
            </Card>
            {route.integration === 'chainflip' &&
              route.steps.map((step) => {
                const token =
                  step.destToken.chainflipId === 'Usdc' ? step.srcToken : step.destToken;

                const asset = getChainflipAsset(
                  token.chain.id,
                  token.address,
                ) as BaseChainflipAsset;

                return (
                  <MarketDataCard
                    step={step}
                    key={asset}
                    asset={asset}
                    setWidth={(newWidth) => setWidth((current) => Math.max(newWidth, current ?? 0))}
                    width={width}
                  />
                );
              })}
          </div>
        </MacScrollbar>
      </Modal>
      <button
        type="button"
        disabled={!chainflipRoute || disabled}
        className={classNames(
          'flex w-full items-center justify-center space-x-1 rounded-md border border-cf-gray-4 bg-cf-gray-3 text-center font-aeonikMedium text-12 text-cf-light-3 transition',
          'disabled:cursor-not-allowed disabled:text-opacity-30',
          'enabled:hover:border-cf-gray-5 enabled:hover:bg-cf-gray-4 enabled:hover:text-cf-white',
          label ? 'p-2' : 'p-[3px]',
          className,
        )}
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
          track(SwapEvents.ViewMarketData, {
            props: {
              assetFrom: `${route.srcToken.chain.name}.${route.srcToken.symbol}`,
              assetTo: `${route.destToken.chain.name}.${route.destToken.symbol}`,
            },
          });
        }}
      >
        <ChartIcon />
        {label && <span>{label}</span>}
      </button>
    </>
  );
}
